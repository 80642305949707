<template>
  <div class="rule">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="el-nav">
      <el-tab-pane label="课程管理" name="courses"></el-tab-pane>
      <el-tab-pane label="查看课程" name="create">
        <el-form :model="coursesForm" :rules="rules" ref="coursesForm" label-position="right" label-width="150px"  class="addForm">
          <el-form-item label="标题">
            <p>{{coursesForm.title}}</p>
          </el-form-item>
          <el-form-item label="副标题" >
            <p>{{coursesForm.subtitle}}</p>
          </el-form-item>

          <el-form-item label="上传移动端封面图" >
            <a :href="coursesForm.mb_cover_picture" :target="coursesForm.mb_cover_picture">
              <img :src="coursesForm.mb_cover_picture" style="max-width: 300px" alt="">
            </a>
          </el-form-item>

          <el-form-item label="上传PC端封面图" >
            <a :href="coursesForm.pc_cover_picture" :target="coursesForm.pc_cover_picture">
              <img :src="coursesForm.pc_cover_picture" style="max-width: 300px" alt="">
            </a>
          </el-form-item>

          <el-form-item label="上传移动端背景图" >
            <a :href="coursesForm.mb_back_picture" :target="coursesForm.mb_back_picture">
              <img :src="coursesForm.mb_back_picture" style="max-width: 300px" alt="">
            </a>
          </el-form-item>

          <el-form-item label="讲师">
            <p>{{coursesForm.lecturer}}</p>
          </el-form-item>
          <el-form-item label="课程标签" prop="tags"  class="tag-input">
<!--            <el-tag type="success" style="margin-right: 4px" v-for="(item, index) in coursesForm.tags" :key="index" >{{item.name}}</el-tag>-->
<!--            <span style="margin-right: 4px;color: #71777D;" v-for="(item, index) in coursesForm.tags" :key="index">{{item.name}},</span>-->
            <el-tag
              v-for="tag in coursesForm.tags"
              :key="tag.id"
              :disable-transitions="false"
            >
              {{tag.name}}
            </el-tag>
          </el-form-item>
          <el-form-item label="难度级别" >
            <p v-if="coursesForm.difficulty_level === 0">入门</p>
            <p v-if="coursesForm.difficulty_level === 1">初级</p>
            <p v-if="coursesForm.difficulty_level === 2">中级</p>
            <p v-if="coursesForm.difficulty_level === 3">高级</p>
          </el-form-item>
          <el-form-item label="SEO关键词">
            <p>{{coursesForm.seo_keywords}}</p>
          </el-form-item>
          <el-form-item label="SEO描述">
            <p>{{coursesForm.seo_description}}</p>
          </el-form-item>

          <el-form-item label="介绍" >
            <div v-html="coursesForm.about" style="line-height: 24px;margin-top: 8px"></div>
          </el-form-item>
          <div class="form-operation">
            <el-button class="form-operation-item"  round @click="cancelFun">返回</el-button>
            <el-button class="form-operation-item" type="primary" round @click="saveSubmit">确认</el-button>
          </div>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import UploadFile from "components/uploadFile";
import _ from 'lodash'
export default {
  name: "Create",
  data() {
    return{
      activeName: 'create',
      levelData: [],  // 等级数据
      tagsData: [], // 标签列表

      coursesForm: {
        title: '',  // 标题
        subtitle: '', // 副标题
        mb_cover_picture: '', //上传移动端封面图
        pc_cover_picture: '', //上传PC端封面图
        mb_back_picture: '',  // 上传移动端背景图
        lecturer: '', //  讲师
        tag_ids: [],  // 课程标签
        difficulty_level: '', // 难度级别
        seo_title: '',  // SEO标题
        seo_keywords: '', // SEO关键词
        seo_description: '',  //SEO描述
        about: '',  //介绍
      },
      rules: {
        title: [
          { required: true, message: '请输入课程标题', trigger: 'blur' },
          { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
        ],
        mb_cover_picture: [
          { required: true, message: '请上传移动端封面图', trigger: 'blur' },
        ],
        pc_cover_picture: [
          { required: true, message: '请上传移动端封面图', trigger: 'blur' },
        ],
        about: [
          { required: true, message: '请输入课程介绍', trigger: 'blur' },
          { max: 5000, message: '长度在 0-5000 个字符', trigger: 'blur' }
        ]
      },  // 验证
      mb_cover_picture_data: [],  // 默认上传移动端封面图
      pc_cover_picture_data: [],  // 默认上传PC端封面图
      mb_back_picture_data: [], // 上传移动端背景图
    }
  },
  created() {
    this.getCourses();
    this.getDictionary();
    this.getTags()
  },
  methods: {
    // 获取课程
    getCourses() {
      let url= this.$api.Courses + '/' + this.$route.params.uuid;
      this.$http.get(url, true).then(res =>{
        if(res.data.success) {
          let course_info= res.data.data
          this.coursesForm.title= course_info.title
          this.coursesForm.subtitle= course_info.subtitle
          this.coursesForm.mb_cover_picture= course_info.mb_cover_picture
          this.coursesForm.pc_cover_picture= course_info.pc_cover_picture
          this.coursesForm.mb_back_picture= course_info.mb_back_picture
          this.coursesForm.lecturer= course_info.lecturer
          this.coursesForm.tags= course_info.tags
          this.coursesForm.difficulty_level= course_info.difficulty_level
          this.coursesForm.seo_title= course_info.seo_title
          this.coursesForm.seo_keywords= course_info.seo_keywords
          this.coursesForm.seo_description= course_info.seo_description
          this.coursesForm.about= course_info.about

          // 设置默认图片
          if(course_info.mb_cover_picture) {
            this.mb_cover_picture_data.push(course_info.mb_cover_picture)
          }
          if(course_info.pc_cover_picture) {
            this.pc_cover_picture_data.push(course_info.pc_cover_picture)
          }
          if(course_info.mb_back_picture) {
            this.mb_back_picture_data.push(course_info.mb_back_picture)
          }
        }
      })
    },
    // tab切换
    handleClick(tab, event) {
      if(tab.name === 'courses') {
        this.$router.push('/courses')
      }
    },
    // 获取标签
    getTags() {
      let url= this.$api.tagsCircle
      this.$http.get(url, true).then(res=>{
        if(res.data.success) {
          this.tagsData= res.data.data
        }
      })
    },
    // 取消
    cancelFun() {
      history.go(-1)
      //window.location.href= window.location.origin + '/courses/courses'
    },
    // 保存
    saveSubmit: _.debounce(function (){
      history.go(-1)
    },500),
    // 返回图片
    resultImg(url, type) {
      if(type === 'mb_cover_picture') {
        this.coursesForm.mb_cover_picture= url[url.length -1]
      }else if(type === 'pc_cover_picture') {
        this.coursesForm.pc_cover_picture= url[url.length -1]
      }else if(type === 'mb_back_picture') {
        this.coursesForm.mb_back_picture= url[url.length -1]
      }
    },
    // 获取等级
    getDictionary() {
      let url= this.$api.Dictionary + '?key=course_difficulty';
      this.$http.get(url, true).then(res =>{
        if(res.data.success) {
          let arr= []
          for (let i in res.data.data){
            arr.push({
              label: res.data.data[i],
              value: parseInt(i)
            })
          }
          this.levelData= arr
        }
      })
    },
  },
  components: {
    UploadFile
  }
}
</script>

<style lang="less" scoped>
@import "~assets/less/courses/index.less";
</style>
